import { handler as createAccountHook } from "./useAccount";
import { handler as createNetworkHook } from "./useNetwork";
import { handler as createMintStateHook } from "./useMintState";
import { handler as createMintedSupplyHook } from "./useMintedSupply";
import { handler as createWhitelistHook } from "./useWhitelist";

export const setupHooks = (web3, provider, contract) => {
  return {
    useAccount: createAccountHook(web3, provider),
    useNetwork: createNetworkHook(web3, provider),
    useMintState: createMintStateHook(web3, contract),
    useMintedSupply: createMintedSupplyHook(web3, contract),
    useWhitelist: createWhitelistHook(web3, contract),
  };
};
