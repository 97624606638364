import useSWR from "swr";

export const handler = (web3, contract) => (d) => {
  const { data, ...rest } = useSWR(
    () => (web3 && contract && d ? `web3/whitelist/${d}` : null),
    async () => {
      const amount = await contract.methods.isWhitelisted(d).call();

      return {
        amount: parseInt(amount),
        isWL: parseInt(amount) > 0,
      };
    }
  );

  return { data, ...rest };
};
