import useSWR from "swr";

export const handler = (web3, contract) => () => {
  const { data, ...rest } = useSWR(
    () => (web3 && contract ? `web3/mintStates` : null),
    async () => {
      const mintState = await contract.methods.isPublicMint().call();
      const earlyMintState = await contract.methods.isEarlyMint().call();

      return { mintState, earlyMintState };
    }
  );

  return { data, ...rest };
};
