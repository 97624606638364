import useSWR from "swr";

export const handler = (web3, contract) => () => {
  const { data, ...rest } = useSWR(
    () => (web3 && contract ? `web3/mintedSupply` : null),
    async () => {
      const totalSupply = await contract.methods.totalSupply().call();
      console.log(totalSupply);

      return totalSupply;
    }
  );

  return { data, ...rest };
};
