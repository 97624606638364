import React from "react";

// Icons
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";

// Hooks
import { useWeb3 } from "components/providers";

const SuccessPage = () => {
  const { isSuccess, setSuccess } = useWeb3();
  return (
    <React.Fragment>
      <p className="text-center text-xl my-2 text-white">Congratulations</p>
      <p className="text-center mt-1 text-md text-white font-thin">
        You have successfully minted {isSuccess ? isSuccess : 0} Magnificent
        Century NFT
      </p>
      <div className="flex flex-col">
        <p className="mx-auto poppins text-center mt-3 text-xl text-white w-10/12">
          You are now able to trade your hidden NFT on Opensea. NFT reveal will
          take place in 3 week. Follow our twitter and discord for details.
        </p>
        <div className="w-11/12 bg-white rounded-xl flex mx-auto my-5 cursor-pointer">
          <p className="mx-auto px-2 text-[#E95095] font-bold text-2.5xl py-1">
            View On Opensea
          </p>
        </div>
      </div>

      <p className="poppins text-center text-lg mt-5 text-white">
        Share your experience on Social Media.
      </p>
      <div className="w-full flex justify-center space-x-10 text-white mb-2 mt-5">
        <FaDiscord size={35} className="cursor-pointer" />
        <FaTwitter size={35} className="cursor-pointer" />
        <BsFacebook size={35} className="cursor-pointer" />
      </div>
      <div
        className="w-11/12 bg-white rounded-xl flex mx-auto my-5 cursor-pointer"
        onClick={() => setSuccess(0)}
      >
        <p className="mx-auto px-2 text-[#E95095] font-bold text-2.5xl py-1">
          Go Back
        </p>
      </div>
    </React.Fragment>
  );
};

export default SuccessPage;
