export const loadContract = async (name, web3) => {
  let contract = null;

  try {
    const res = await fetch(`/contracts/${name}.json`);
    const Artifact = await res.json();

    // let networkId = 43113; // Rinkeby Testnet
    let networkId = 1337; // Ganache
    console.log(Artifact.networks[networkId].address);

    contract = new web3.eth.Contract(
      Artifact.abi,
      "0x13eeaDA142a7621C58A8Bc94C1B4292Ed5a5E313"
    );

    console.log(`Contract ${name} loaded`);
  } catch (e) {
    console.log(e);
    console.log(`Contract ${name} not found`);
  }

  return contract;
};
