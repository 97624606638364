import React from "react";

import { Menu } from "@headlessui/react";

// Hooks
import { useWeb3 } from "components/providers";
import { useAccount, useMintState } from "components/hooks/web3";

const AmountMint = ({ onShow, onHide }) => {
  const { contract, setSuccess } = useWeb3();
  const { account } = useAccount();
  const { states } = useMintState();

  const [isProcessing, setIsProcessing] = React.useState(false);
  const [amount, setAmount] = React.useState(1);

  const onClickMint = async () => {
    const price = await contract.methods.NFT_PRICE().call();

    setIsProcessing(true);
    if (states?.data?.earlyMintState === true) {
      await contract.methods
        .earlyClaim(amount)
        .send({ from: account?.data, value: price * amount })
        .then(
          () => setSuccess(amount),
          (err) => {
            onShow();
            setIsProcessing(false);
          }
        );
    } else if (states?.data?.mintState === true) {
      await contract.methods
        .claim(amount)
        .send({ from: account?.data, value: price })
        .then(
          () => setSuccess(amount),
          (err) => {
            onShow();
            setIsProcessing(false);
          }
        );
    }
  };

  return (
    <div className="flex flex-col w-full my-3">
      <select
        id="cars"
        name="cars"
        className="w-5/12 mx-auto text-center rounded-xl py-1 px-2"
        onChange={(e) => setAmount(e.target.value)}
      >
        {Array.from({ length: 10 }, (_, i) => i + 1).map((i) => (
          <option key={i} value={i}>
            {i}
          </option>
        ))}
      </select>
      <div
        className="w-6/12 bg-white rounded-3xl flex mx-auto my-2 cursor-pointer mt-3"
        onClick={onClickMint}
      >
        <p className="mx-auto px-2 text-[#E95095] font-bold text-2.5xl py-1">
          {isProcessing ? "Processing..." : "Mint NFT"}
        </p>
      </div>
    </div>
  );
};

export default AmountMint;
