import React from "react";

// Hooks
import {
  useAccount,
  useMintState,
  useNetwork,
  useWhitelist,
} from "components/hooks/web3";

// // Components
import { AmountMint } from "components/ui/AmountMint";

const MintButton = ({ onShow, onHide }) => {
  const { account } = useAccount();
  const { states } = useMintState();
  const { whitelist } = useWhitelist(account?.data);
  const { network } = useNetwork();

  return (
    <React.Fragment>
      {states?.data?.mintState ? (
        <AmountMint onShow={onShow} onHide={onHide} />
      ) : (
        <React.Fragment>
          {whitelist?.data?.isWL ? (
            states?.data?.earlyMintState ? (
              <AmountMint onShow={onShow} onHide={onHide} />
            ) : (
              <div className="w-9/12 bg-white rounded-lg text-black text-sm flex mx-auto font-bold py-2 px-1 mt-2">
                <p className="mx-auto">You are whitelisted. Wait for WL sale</p>
              </div>
            )
          ) : (
            <div className="w-9/12 bg-white rounded-lg text-black text-sm flex mx-auto font-bold py-2 px-1 mt-2">
              <p className="mx-auto">
                You are not whitelisted. Wait for (FCFS)
              </p>
            </div>
          )}
        </React.Fragment>
      )}

      {account?.data &&
      whitelist?.data?.isWL === false &&
      states?.data?.mintState === false ? (
        <p className="poppins text-center my-1 text-xmd text-white font-light">
          Connect Wallet {">"} Mint NFT (Wait for FCFS)
        </p>
      ) : (
        <p className="poppins text-center my-1 font-light text-xmd text-white">
          Connect Wallet {">"} Mint NFT
        </p>
      )}

      {network?.hasInitialResponse === true ? (
        <React.Fragment>
          {network?.isSupported === true ? (
            <p className="text-center text-white text-sm font-bold mt-2">
              Ethereum Network
            </p>
          ) : (
            <p className="text-center text-white text-sm font-bold mt-2">
              Please switch Ethereum Network
            </p>
          )}
        </React.Fragment>
      ) : null}

      <div className="bg-white h-0.5  w-full mt-3 mb-4"></div>
    </React.Fragment>
  );
};

export default MintButton;
