import React from "react";

// Hooks
import { useWeb3 } from "components/providers";
import { useAccount } from "components/hooks/web3";

import bg from "./images/bg.png";
import content from "./images/content.png";
import list from "./images/list.png";

import { MintButton } from "components/ui/MintButton";

// Icons
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";
import { SuccessPage } from "components/ui";

function App() {
  const { account } = useAccount();
  const { connect, isSuccess, contract } = useWeb3();

  const [showErrorToast, setShowErrorToast] = React.useState(false);
  const [isConnecting, setIsConnecting] = React.useState(false);

  const onShowErrorToast = () => setShowErrorToast(true);

  const onHideErrorToast = () => setShowErrorToast(false);

  const onClickConnect = () => {
    setIsConnecting(true);
    connect().then(() => setIsConnecting(false));
  };

  return (
    <div
      className="w-max h-max flex bg-cover bg-top"
      style={{
        backgroundImage: "url(" + bg + ")",
        width: "100%",
        height: "100vh",
      }}
    >
      <div className="w-11/12 flex m-auto bg-[#333333] flex-col rounded md:w-8/12 lg:w-4/12">
        {isSuccess !== 0 ? (
          <SuccessPage />
        ) : (
          <React.Fragment>
            <p className="poppins font-bold text-center text-white text-xl pt-2 pb-1">
              Mint your NFT!
            </p>
            <p className="poppins text-center text-white text-xl">
              Be a Part Of Our Magnificent Journey
            </p>
            <p className="poppins text-center text-white text-xs w-10/12 mx-auto pt-1 ">
              Get your NFT NOW, don{"’"}t be left out, and bea part of our new
              ERA where we grow and enjoy together!
            </p>
            <img
              src={content}
              className=" mx-auto aspect-square rounded-md w-7/12 h-auto pt-4 px-3 pb-1 bg-contain lg:w-5/12"
              alt=".."
            />
            <p className="text-center poppins text-light text-sm text-white">
              New to NFT's?{" "}
              <a
                href="https://www.google.com.tr"
                target={"_blank"}
                rel="noreferrer"
                className="font-bold"
              >
                Learn Here
              </a>
            </p>
            {account?.data && (
              <p className="poppins text-center mb-0 text-white text-xmd mt-1">
                Connected as {account?.data.slice(0, 10)}...
              </p>
            )}
            {account?.data ? (
              <MintButton onShow={onShowErrorToast} onHide={onHideErrorToast} />
            ) : (
              <div
                className="w-9/12 bg-white rounded-3xl flex mx-auto my-2 cursor-pointer"
                onClick={connect}
              >
                <p className="mx-auto px-2 text-[#E95095] font-bold text-2.5xl py-1.5">
                  Connect Wallet
                </p>
              </div>
            )}

            <p className="text-center font-light text-lg text-white">
              Sale Details
            </p>

            <div className="w-full grid grid-cols-2 gap-y-3 my-2">
              {Array.from([
                { name: "Mint Price", desc: "0.15 ETH / 500 USD" },
                { name: "Items for Sale", desc: "7389" },
                {
                  name: "WL Sale Date",
                  desc: "1st June, 9pm CET",
                },
                {
                  name: "Public Sale Date",
                  desc: "1 Hour After WL",
                },
                {
                  name: "Max Per Purchase",
                  desc: "10 pieces",
                },
                {
                  name: "Max buy per wallet",
                  desc: "50 pieces",
                },
              ]).map((elem) => (
                <div>
                  <div className="w-10/12 mx-auto bg-white py-2 grid grid-cols-12 rounded-xl">
                    <div className=" col-span-3 flex">
                      <img
                        src={list}
                        alt=".."
                        className="w-6 h-6 m-auto aspect-square"
                      />
                    </div>
                    <div className="col-span-9 flex flex-col">
                      <p className="font-bold text-xmd text-left">
                        {elem.name}
                      </p>
                      <p className="text-xmd text-left">{elem.desc}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="w-full flex justify-center space-x-10 text-white my-2">
              <FaDiscord size={40} className="cursor-pointer" />
              <FaTwitter size={40} className="cursor-pointer" />
              <BsFacebook size={40} className="cursor-pointer" />
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default App;
